
const birdAr = {
  Buyer: 'المشتري',
  Seller: 'بائع',
  Referal: 'دعوة',
  General: 'كامل',
  Point: 'نقطة',
  OneTime: 'مرة واحدة',
  Repeatable: 'التكرار',
  ReferalNumber: 'رمز الدعوة',
  Balance: 'التوازن',
  LoyaltyPoints: 'نقاط EFAH',
  DigitalAssets: 'الأصول الرقمية',
  Mission: 'مهمة',
  PointEarned: 'نقاط EFAH المكتسبة',
  LoyaltyPoint: 'رصيد نقاط EFAH',
  Sign: 'الاختيار اليومي',
  InvitationCode: 'يقدم رمز الدعوة هنا',
  Blockchain: 'كتلة سلسلة',
  Account_balance: 'الكمية',
  Google: 'قوقل .',
  Facebook: 'فيس بوك .',
  continueWithEmail: 'الاستمرار في إرسال البريد الإلكتروني',
  AuthorizeWeb3Auth: 'إذن حسابك باستخدام web3auth',
  No_data: 'لا توجد بيانات',
  CheckIn: 'تحقق بنجاح',
  CopySuccess: 'نسخ',
  Token_name: 'اسم رمزي',
  Holder_adress: 'عنوان حامل',
  Available:'متاحة',
  Not_available: 'غير قابل للاستعمال',
  ChangeAvatar: 'تغيير الصورة',
  Selectavatar: 'اختيار الصورة الرمزية الخاصة بك',
  Uploadphoto: 'تحميل صور',
  continueWithMetaMask: 'استخدام اتصال ميتاماسك',
  InstalledMetaMask: 'ميتاماسك لم يتم تثبيت ؟',
  ToInstalledMetaMask: 'الذهاب إلى شبكة الإنترنت وتثبيت متصفح ميتاماسك',
  Okay: 'نعم .',
  DisconnectMetaMask: 'قطع ميتاماسك',
  LogoutWeb3Auth: 'إلغاء web3auth',
  Cut: 'تأكيد هذا الخفض',
  Exceed: 'حجم الصورة الرمزية لا يمكن أن يتجاوز 5M',
  Buy: 'يشتري',
  ConfirmDeletePurchaseItem: 'هل تريد تأكيد حذف هذا العنصر من المشتريات؟',
  Clear: 'واضح',
  Help: 'بحاجة لبعض المساعدة؟ قد تتمكن من العثور على إجابة لسؤالك على موقعنا',
  Page: 'صفحة',
  PhoneNumber: 'رقم التليفون',
  OurCustomer: 'مستشارو خدمة العملاء لدينا متاحون من الاثنين إلى الأحد (10 صباحًا - 5 مساءً).',
  WeWill: 'وسوف نهدف إلى الرد عليك في أسرع وقت ممكن. إذا كان استفسارك يتعلق بطلب معين، فيرجى إدراج رقم الطلب الخاص بك.',
  ItemSold: 'تم بيع السلعة',
  Verify: 'يؤكد',
  ProductType: 'نوع المنتج',
  Pleaseagree: 'من خلال تقديم العنصر الخاص بك، فإنك توافق على شروط وأحكام الصقور.',
  BySubmitting: 'من خلال تقديم العنصر الخاص بك، فإنك توافق على',
  OtherInclusions: 'مرفقات أخرى',
  Color: 'لون',
  NewFWAddress: 'العنوان: مركز فيدر التجاري، معيصم أولاً، مدينة دبي للإنتاج',
  LocationOnGoogleMap: 'الموقع على خرائط جوجل',
  EstimatedPayout: 'الدفع المقدر',
  TotalPayable: 'مجموع المستحق الدفع',
  detailTitle04: 'الضرائب',
  detailText04: 'الأسعار المدرَجة قد تخضع لضريبة القيمة المضافة بنسبة 5% وأي ضرائب أخرى.',
  ReadMore: 'اقرأ أكثر',
  BreakLive: 'شاهدوا عمليات فتح العُلَب بشكل مباشر',
  BreakLive_desc1: 'انغمسوا في عالَم البطاقات الرياضية الفريدة، حيث نكشف النقاب عن كنوز من عوالم الفورمولا 1 وكرة السلة وكرة القدم والبيسبول والهوكي وغيرها من الرياضات العريقة.',
  BreakLive_desc2: 'ترتقي حلقات البث المباشر لدينا بفنِّ جمع البطاقات الفريدة إلى آفاق جديدة، ما يتيح للهواة مكاناً في الصف الأمامي في عالَم جمع الجواهر النفيسة والتوقيعات النادرة والقطع التذكارية المميزة.',
  OnlineLive: 'التسوق المباشر عبر الإنترنت',
  OnlineLive_desc: 'رحلة عبر التراث اللامع لجمع البطاقات الرياضية. من العصر الذهبي للكلاسيكيات القديمة إلى المجموعات النادرة الحديثة، تحتفل Falcons بالإرث الدائم لهذه الهواية بكل احترام ورقي.',
  SignUpAgreeEmail: 'توافق على قبول رسائل البريد الإلكتروني الترويجية من الصقور',
  dialColor: 'لون لمينا',
  firstName: 'الاسم الأول',
  lastName: 'اسم العائلة',
  ValidateFirstName: 'الرجاء إدخال الاسم الأول',
  ValidateLastName: 'الرجاء إدخال اسم العائلة',
  firstNameRule: 'يجب أن يتراوح الاسم الأول بين 2 و50 حرفًا',
  lastNameRule: 'يجب أن يتراوح اسم العائلة بين 2 و50 حرفًا',
  exploreMore: 'استكشاف المزيد',
  wordOfFalcons: 'عالم فالكونز',
  blogDec01: 'حيث تتكشف القصص الرائعة.',
  blogDec02: 'حقيبة بيركين: استثمارٌ خالد في عالَم الموضة والأناقة',
  blogDec03: 'شرحُ ظاهرة رولكس',
  blogDec04: 'ظهور اتجاه قطع الموضة الفاخرة المحبوبة مسبقاً',
  blogBir01: '14 مايو 2024',
  blogBir02: '5 مايو 2024',
  blogBir03: '25 أبريل 2024',
  investment: ' استثمارٌ خالد في عالَم الموضة والأناقة',
  birkin: 'حقيبة بيركين',
  blogBannerDesc: `كونها رمزاً للفخامة والرُّقي، فإنَّ امتلاكَ حقيبة بيركين لا يرتبط فقط بالحصول على قطعة إكسسوار جميلة، بل يرتبط أيضاً بالاستثمار الدَّائم في كلٍّ من أسلوبك ومحفظتك المالية.`,
  Accessories: 'الملحقات (مثل الأشرطة والأدلة على سبيل المثال.)',
  AccessoriesTxt: 'مُكَمِّلات',
  OriiginalPackaging: 'التغليف الأصلي',
  chooseAccessories: 'الرجاء اختيار الملحقات',
  chooseOriginal: 'الرجاء اختيار التغليف الأصلي',
  proof: 'إثبات الأصالة',
  ImageType: 'تنسيقات الصور:',
  documentsType: 'تنسيقات المستندات:',
  Receipts: 'الإيصالات',
  ReceiptsDesc: 'تقديم إيصالات الشراء الأصلية أو إثبات الشراء.',
  Certificates: 'الشهادات',
  CertificatesDesc: 'تضمين شهادات الأصالة إذا كانت متوفرة.',
  Serial: 'الأرقام التسلسلية',
  SerialDesc: 'تأكد من ظهور أي أرقام تسلسلية أو علامات تعريفية في الصور.',
  KeepInMind: 'لنأخذ في الاعتبار',
  CloseUp: 'اغلاق متابعة الطلقات:',
  CloseUpDesc: 'قم بتضمين لقطات مقربة لعلامات العلامة التجارية والأرقام التسلسلية وأي عيوب.',
  HighQuality: 'صور عالية الجودة:',
  HighQualityDesc: 'قم بتحميل صور عالية الدقة للعنصر من زوايا متعددة (الأمامية والخلفية والجوانب والداخلية).',
  WhiteBackground: 'خلفية بيضاء:',
  WhiteBackgroundDesc: 'يجب أن يتم التقاط جميع الصور على خلفية بيضاء مع عدم وجود صور أو أشياء مرئية بخلاف العنصر الموجود في الصورة.',
  typeHere: 'إذا كانت أخرى، يرجى الكتابة هنا',
  cartEmpty: 'أوه لا عربة التسوق الخاصة بك فارغة'
}

export default birdAr